@import 'libs/shared/src/lib/utils/utils';
@import 'libs/shared/src/lib/utils/fonts';
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

html,
body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Artegra', sans-serif;
}

h1,
h2,
h3 {
  font-family: 'Artegra-Bold';
}

.primary-color {
  color: #6051e0 !important;
}

.accent-color {
  color: #000000;
}

.warning-color {
  color: #ffcd1c;
}

.error-color {
  color: #fc4e69 !important;
}

.success-color {
  color: #389e0d;
}

.deep-green-color {
  color: #008d62 !important;
}

p {
  b,
  strong {
    font-family: 'Artegra-Bold';
  }
  a {
    font-family: 'Artegra-Semibold';
  }
}

.defaultModal .ant-modal-content {
  min-height: 100vh;
}

.ant-modal-header,
.ant-modal-footer,
.ant-modal-body {
  border: none;
  padding: 1.5rem !important;
  margin: 0 !important;
}

.auth-circle {
  width: 62px;
  height: 62px;
  margin-left: 10px;
  background-color: #6d54fc;
  border-radius: 50%;
  margin-top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth-circle-table {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  background-color: #f1f1f1;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.initial-letter-table {
  color: var(--Purple-B2B-Light-Purple-Table, #181818);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.initial-letter {
  color: var(--Purple-B2B-Light-Purple-Table, #f7f6fc);
  text-align: center;
  font-family: 'Poppins-Semibold';
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
